<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      showModal: false,
      filter:"",
      sortDesc: true,
                fields: [
                    {
                        key: "first_name",
                        label: "Subscriber's Name",
                        sortable: true,
                    },
                    {
                        key: "email",
                        label: "Email",
                        sortable: true,
                    },
                    {
                        key: "phone_number",
                        label: "Phone Number",
                        sortable: true,
                    },
                    {
                        key: "timestamp",
                        label: "Date of Subscription",
                        sortable: true,
                    },
                    
                  ],
    };
  },
  computed: {
    /**
     * Filters the event members based on the search input
     */
    filteredMembers() {
      if (!this.data || !this.data.event_info || !this.data.event_info.event_members) {
      return [];
    }

    if (!this.filter) {
      return this.data.event_info.event_members;
    }

      const searchText = this.filter.toLowerCase();
      return this.data.event_info.event_members.filter((member) => {
        return (
          (member.first_name?.toLowerCase() || "").includes(searchText) ||
          (member.email?.toLowerCase() || "").includes(searchText) 
        );
      });
    },
  },
  watch: {
    showModal(newValue) {
      if (newValue) {
        this.filter = "";
      }
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
   

  },
  
};
</script>

<template>
    <b-modal id="view_event_info" v-model="showModal" title="Subscribers List"  title-class="font-18" size="lg">
      <div class="row">
          <!-- Search -->
          <div class="col">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->

      </div>
        <div class="row">
            <div class="col text-center">
                <h5 class="text-uppercase font-weight-bold">Event Info</h5>
            </div>
 </div>

    <div class="row mb-3">
      <div class="col">
        <div class="d-flex justify-content-between align-items-center">
          <div class="flex-fill text-center">
            <h6 class="text-uppercase">Event Name</h6>
            <p>{{ data.title || '' }}</p>
          </div>
          <div class="flex-fill text-center">
            <h6>Date & Time</h6>
            <p>{{ data.start_at || '' }}</p>
          </div>
          <div class="flex-fill text-center">
            <h6>Location</h6>
            <p>{{ data.location || '' }}</p>
          </div>
          <div class="flex-fill text-center">
            <h6>Subscription Limit</h6>
            <p>{{ data.userLimit || '' }}</p>
          </div>
        </div>
      </div>
    </div>
      <div v-if="filteredMembers.length > 0">
      <div class="table-responsive mt-3">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Subscriber's Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Date of Subscription</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(member, index) in filteredMembers" :key="index">
              <td>{{ member.first_name || '' }}</td>
              <td>{{ member.email || '' }}</td>
              <td>{{ member.phone_number || '' }}</td>
              <td>{{ member.timestamp || '' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="text-center" v-else>
      <p>No event subscribers found.</p>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
    </template>
    </b-modal>
  </template>