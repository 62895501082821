<script>
import ServiceTicketService from "@/services/ServiceTickets";

export default {
  props: ['ticketDetails'], // Pass the ticket details as a prop
  data() {
    return {
      editableTicket: {}, // Local copy for editing
    };
  },
  watch: {
    // When the ticketDetails prop changes, update the local editableTicket
    ticketDetails: {
      handler(newVal) {
        this.editableTicket = { ...newVal };
      },
      immediate: true,
    },
  },
  methods: {
    async saveChanges() {
      const payload = {
        id: this.editableTicket.id,
        guarantee: this.editableTicket.guarantee,
        first_name: this.editableTicket.first_name,
        last_name: this.editableTicket.last_name,
        company_name: this.editableTicket.company_name,
        phone: this.editableTicket.phone,
        email: this.editableTicket.email,
        city: this.editableTicket.city,
        address: this.editableTicket.address,
        product_model: this.editableTicket.product_model,
        product_serial: this.editableTicket.product_serial,
        fiscal_slip: this.editableTicket.fiscal_slip,
        guarantee_list_scan: this.editableTicket.guarantee_list_scan,
        description: this.editableTicket.description,
        status: this.editableTicket.status,
        created: this.editableTicket.created,
        timestamp: this.editableTicket.timestamp,
      };

      try {
        // console.log(JSON.stringify(this.editableTicket));
        // const response = await fetch(`http://scapi.net/merchandise/serviceTicket/${this.ticketDetails.ticket_id}`, {
        //   method: 'PUT',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'SCAPI-KEY': 'RIV-01-RIVERSHOP-202202',
        //   },
        //   body: JSON.stringify(payload),
        // });
        // alert(JSON.stringify(payload));
        const response = await ServiceTicketService.updateServiceTicket(this.ticketDetails.ticket_id,JSON.stringify(payload));
        if (response.status >= 200 && response.status < 300) {
          this.$emit('update-success', this.editableTicket); // Emit event for parent to update
          this.$bvModal.hide('edit_service_ticket');
          window.location.reload();
        } else {
          throw new Error('Failed to save changes');
        }
      } catch (error) {
        console.error("Error updating ticket:", error);
        alert("Error saving changes. Please try again.");
      }
    },

    async deleteTicket() {
      try {
        // API call to delete the ticket
        // const response = await fetch(`http://scapi.net/merchandise/serviceTicket/${this.ticketDetails.ticket_id}`, {
        //   method: 'DELETE',
        //   headers: {
        //     'SCAPI-KEY': 'RIV-01-RIVERSHOP-202202',
        //   },
        // });
        const response = await ServiceTicketService.deleteServiceTicket(this.ticketDetails.ticket_id);

        const data = response.data;

        if (response.status >= 200 && response.status < 300) {
          // Emit an event to the parent to refresh the table
          this.$emit('refreshTable');
          this.closeModal(); // Close the modal after successful deletion
          window.location.reload();

        } else {
          // Handle error (show a message or log it)
          console.error('Error deleting ticket:', data);
        }
      } catch (error) {
        console.error('Error deleting ticket:', error);
      }
    },
    confirmDelete() {
      this.$bvModal.show('confirmDeleteModal');
    },
    closeModal() {
      this.$bvModal.hide('edit_service_ticket');
    },
    closeDeleteModal() {
      this.$bvModal.hide('confirmDeleteModal');
    },
  },
};
</script>

<template>
  <b-modal id="edit_service_ticket" title="Edit Service Ticket" @hidden="closeModal" size="xl" centered>
    <template v-slot:default>
      <div class="row">
        <div class="col-sm-6">
          <b-form-group label="First Name">
            <b-form-input v-model="editableTicket.first_name" />
          </b-form-group>
          <b-form-group label="Last Name">
            <b-form-input v-model="editableTicket.last_name" />
          </b-form-group>
          <b-form-group label="Guarantee">
            <b-form-select v-model="editableTicket.guarantee" :options="[
      { value: 0, text: 'No' },
      { value: 1, text: 'Yes' }
    ]" />
          </b-form-group>
          <b-form-group label="Company Name">
            <b-form-input v-model="editableTicket.company_name" />
          </b-form-group>
          <b-form-group label="Phone">
            <b-form-input v-model="editableTicket.phone" />
          </b-form-group>
        </div>
        <div class="col-sm-6">
          <b-form-group label="Email">
            <b-form-input v-model="editableTicket.email" type="email" />
          </b-form-group>
          <b-form-group label="City">
            <b-form-input v-model="editableTicket.city" />
          </b-form-group>
          <b-form-group label="Address">
            <b-form-input v-model="editableTicket.address" />
          </b-form-group>
          <b-form-group label="Product Model">
            <b-form-input v-model="editableTicket.product_model" />
          </b-form-group>
          <b-form-group label="Product Serial">
            <b-form-input v-model="editableTicket.product_serial" />
          </b-form-group>
          <b-form-group label="Fiscal Slip">
            <b-form-input v-model="editableTicket.fiscal_slip" />
          </b-form-group>
          <b-form-group label="Guarantee list scan">
            <b-form-input v-model="editableTicket.guarantee_list_scan" />
          </b-form-group>
          <b-form-group label="Status">
            <b-form-select v-model="editableTicket.status"  :options="[
        { value: 0, text: '0-Сервис тикетот е одбиен' },
        { value: 1, text: '1-Нов сервис тикет' },
        { value: 2, text: '2-Сервис тикетот е отворен' },
        { value: 3, text: '3-Уредот е подигнат за сервис' },
        { value: 4, text: '4-Уредот се сервисира' },
        { value: 5, text: '5-Уредот е сервисиран' },
        { value: 6, text: '6-Уредот чека на испорака' },
        { value: 7, text: '7-Уредот е доставен' }
    ]"  />
          </b-form-group>
        </div>
      </div>
      <b-form-group label="Description">
        <b-form-textarea v-model="editableTicket.description" rows="3" />
      </b-form-group>
    </template>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Cancel</b-button>
      <b-button variant="danger" @click="confirmDelete">Delete</b-button> <!-- Delete Button -->

      <!-- Confirmation Modal -->
      <b-modal id="confirmDeleteModal" title="Confirm Deletion" @hidden="closeDeleteModal" size="sm" centered>
        <p>Are you sure you want to delete this service ticket?</p>
        <template v-slot:modal-footer>
          <b-button variant="light" @click="closeDeleteModal">Cancel</b-button>
          <b-button variant="danger" @click="deleteTicket">Confirm Delete</b-button>
        </template>
      </b-modal>
      <b-button variant="primary" @click="saveChanges">Save Changes</b-button>
    </template>
  </b-modal>
</template>
