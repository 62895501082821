<script>
import { required, numeric } from "vuelidate/lib/validators";
import validationMessages from "@/components/validations";
import Merchandising from "@/services/Merchandising";
import Swal from "sweetalert2";
import vueDropzone from "vue2-dropzone";

export default {
  components: { vueDropzone, validationMessages },

  data() {
    return {
      brand_name: "",
      showModal: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem("csrf_token"),
      selectLogo: "",
      selectHeader: "",
      brands: null,
      productsBrands: [], // Changed to an array
      display_order: "",
      dropzoneOptions_logo: {
        url: "/",
        autoProcessQueue: false,
        addRemoveLinks: true,
        thumbnailWidth: 300, // Adjust as needed
        thumbnailHeight: 200, // Adjust to match container aspect ratio
        thumbnailMethod: "contain",
        maxFilesize: 2,
        acceptedFiles: "image/*",
        maxFiles: 1,
      },
      dropzoneOptions_header: {
        url: "/",
        autoProcessQueue: false,
        addRemoveLinks: true,
        thumbnailWidth: 200,
        thumbnailMethod: "contain",
        maxFilesize: 2,
        acceptedFiles: "image/*",
        maxFiles: 1,
      },
    };
  },

  validations: {
    brand_name: { required },
    brands: { required },
    display_order: { required, numeric },
  },

  methods: {
    async getBrands() {
      this.showLoader = true;
      try {
        const limit = "limit=10000";
        const response = await Merchandising.getProducts(limit);
        const products = response.data.data.products;

        // Filter out empty or invalid values and create unique brands
        const brands = [
          ...new Map(
              products
                  .filter((product) => product.Art_Proizvoditel?.trim())
                  .map((product) => [
                    product.Art_Proizvoditel,
                    { value: product.Art_Proizvoditel, text: product.Art_Proizvoditel },
                  ])
          ).values(),
        ];

        // Sort brands alphabetically
        this.productsBrands = brands.sort((a, b) => a.text.localeCompare(b.text));
      } catch (error) {
        console.error("Error fetching brands:", error);
        Swal.fire("Error", "Failed to fetch brands. Please try again.", "error");
      } finally {
        this.showLoader = false;
      }
    },

    async submitForm() {
      this.tryingToSubmit = true;

      if (this.$v.$invalid) {
        this.submitted = true;
        this.tryingToSubmit = false;
        return;
      }

      this.showLoader = true;
      let formData = new FormData();
      formData.append("brand_name", this.brand_name);
      formData.append("brand_code", this.brands);
      formData.append("display_order", this.display_order);

      const filesLogo = this.$refs.myVueDropzone_logo.getAcceptedFiles();
      if (filesLogo.length > 0) {
        formData.append("logo_img", filesLogo[0]);
      }

      const filesHeader = this.$refs.myVueDropzone_header.getAcceptedFiles();
      if (filesHeader.length > 0) {
        formData.append("header_image", filesHeader[0]);
      }

      try {
        const response = await Merchandising.addBrand(formData);

        console.log(response.success)
        console.log(response.error)
        if (response.success === false) {
          Swal.fire("Error", response.error || "Failed to create brand. Please try again.", "error");
        } else {
          Swal.fire("Success", "Brand created successfully!", "success");
          this.closeModal();
          this.refreshData();
        }

      } catch (error) {
        // Catch any network or unexpected errors
        console.error("Failed to create brand:", error);
        Swal.fire("Error", error.response?.data?.error || "Failed to create brand. Please try again.", "error");
      } finally {
        this.tryingToSubmit = false;
        this.showLoader = false;
      }
    },


    resetForm() {
      this.brands = null;
      this.productsBrands = [];
      this.brand_name = "";
      this.display_order = "";
      this.$v.$reset();

      if (this.$refs.myVueDropzone_logo) {
        this.$refs.myVueDropzone_logo.removeAllFiles();
      }
      if (this.$refs.myVueDropzone_header) {
        this.$refs.myVueDropzone_header.removeAllFiles();
      }
    },

    closeModal() {
      this.resetForm();
      this.showModal = false;
    },

    setData() {
      this.getBrands();
    },

    refreshData() {
      this.$emit("onRefresh");
    },
  },
};
</script>

<template>
  <b-modal
      @shown="setData"
      @hidden="resetForm"
      id="add_brand"
      size="l"
      v-model="showModal"
      title="Add Brand"
      title-class="font-18"
  >
    <form @submit.prevent="submitForm">
      <div class="row">
        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
        <div v-if="showLoader" class="card-body d-flex align-items-center justify-content-center">
          <b-spinner large></b-spinner>
        </div>
        <div v-else>
          <b-card-text>
            <b-form-group label="Brand Name" class="mb-3">
              <b-form-input
                  v-model.trim="brand_name"
                  :class="{ 'is-invalid': submitted && $v.brand_name.$error }"
              ></b-form-input>
              <validationMessages
                  v-if="submitted"
                  :fieldName="'Brand Name'"
                  :validationName="$v.brand_name"
              ></validationMessages>
            </b-form-group>

            <b-form-group label="Origin Brand Name" class="mb-3">
              <b-form-select
                  id="brands-select"
                  v-model="brands"
                  :options="productsBrands"
                  :class="{ 'is-invalid': submitted && $v.brands.$error }"
                  placeholder="Select a brand"
              ></b-form-select>
            </b-form-group>

            <b-form-group label="Logo Image" class="mb-3">
              <vue-dropzone
                  ref="myVueDropzone_logo"
                  id="logo-dropzone"
                  :options="dropzoneOptions_logo"
              ></vue-dropzone>
            </b-form-group>

            <b-form-group label="Header Image" class="mb-3">
              <vue-dropzone
                  ref="myVueDropzone_header"
                  id="header-dropzone"
                  :options="dropzoneOptions_header"
              ></vue-dropzone>
            </b-form-group>

            <b-form-group label="Display Order" class="mb-3">
              <b-form-input
                  v-model.trim="display_order"
                  :class="{ 'is-invalid': submitted && $v.display_order.$error }"
              ></b-form-input>
              <validationMessages
                  v-if="submitted"
                  :fieldName="'Display Order'"
                  :validationName="$v.display_order"
              ></validationMessages>
            </b-form-group>
          </b-card-text>
        </div>
      </div>
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="submitForm" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
      </b-button>
    </template>
  </b-modal>
</template>
<style>
#logo-dropzone .dz-preview .dz-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#logo-dropzone .dz-preview .dz-image img {
  object-fit: contain; /* Ensures the image scales without cropping */
  max-width: 100%;
  max-height: 100%;
}

</style>
