<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Merchandising from "@/services/Merchandising";

export default {
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Categories",
      categories: [],
      isBusy: false,
      checked: false,
      updateSlugButtonVisibility: [],
      updateOrderButtonVisibility: [],
      // showDropDown:false,
      // categoryVisibility:0,
      isDropdownOpen: [],
      isSubSubDropdownOpen: [],
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },

  async created() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      try {
        this.toggleBusy();
        const response = await Merchandising.getCategories();
        this.categories = response.data.data;
        this.toggleBusy();
        console.log(this.categories);
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
      }
    },

    async updateCategorySlug(id, category_slug) {
      try {
        this.toggleBusy();

        const response = await Merchandising.updateCategorySlug(
          id,
          category_slug
        );
        for (const key in this.updateSlugButtonVisibility) {
          if (this.updateSlugButtonVisibility[key]) {
            this.$set(this.updateSlugButtonVisibility, key, false);
          }
        }
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
      }
    },
    async updateCategoryOrder(id, order) {
      try {
  
        const response = await Merchandising.updateCategoryOrder(id, order);
        for (const key in this.updateOrderButtonVisibility) {
          if (this.updateOrderButtonVisibility[key]) {
            this.$set(this.updateOrderButtonVisibility, key, false);
          }
        }
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
      }
    },
    async updateCategoryVisibility(id,oldVisibility){
      try{
        const response = await Merchandising.updateCategoryVisibility(id,oldVisibility);
          console.log(this.categories,'categories in visibility function');
        
  console.log('Visibility updated successfully:', response.data);
      }catch(error){
        this.error = error.response.data.error ? error.response.data.error : "";

      }
    },
    toggleDropdown(index) {
      // Initialize the index in isDropdownOpen if not already set
      this.$set(this.isDropdownOpen, index, !this.isDropdownOpen[index]);
      console.log(this.isDropdownOpen[index], "top level arr");
      // If the dropdown is being closed, reset the sub-dropdown states
      if (this.isDropdownOpen[index] === false) {
        for (const key in this.isSubSubDropdownOpen) {
          if (this.isSubSubDropdownOpen[key]) {
            this.$set(this.isSubSubDropdownOpen, key, false);
          }
        }
      }
    },

    handleInputOrderFocus(index) {
      this.$set(this.updateOrderButtonVisibility, index, true);
    },
    handleInputOrderBlur(index) {
      this.$set(this.updateOrderButtonVisibility, index, false);
    },

    handleInputSlugFocus(index) {
      this.$set(this.updateSlugButtonVisibility, index, true);
    },
    handleInputSlugBlur(index) {
      this.$set(this.updateSlugButtonVisibility, index, false);
    },

    toggleSubSubLevel(subIndex) {
      this.$set(
        this.isSubSubDropdownOpen,
        subIndex,
        !this.isSubSubDropdownOpen[subIndex]
      );
      console.log("here");
      console.log(this.isSubSubDropdownOpen[subIndex], "sub level arr");
    },
      
     
  },
  mounted() {
    this.isDropdownOpen = Array(this.categories.length).fill(false);
    // this.isSubSubDropdownOpen = Array(this.categories.length).fill(false);
    // this.isSubSubDropdownOpen = this.categories.forEach(category=>{
    //     category.subcategories.forEach(subCategory=>{
    //         Array(subCategory.subsubcategories.length).fill(false);
    //     })
    // })
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
            <div
              class="card-header"
              style="
                background-color: #ffffff;
                font-weight: bold;
                border-bottom: 2px solid #e3e3e3;
                font-size:16px;
                padding:0.75rem 1.25rem;

              "
            >
              <div class="row" style="padding:12px;">
                <div class="col-sm-1">ID</div>
                <div class="col-sm-3">Name</div>
                <div class="col-sm-3">Order</div>
                <div class="col-sm-3">Slug</div>
                <div class="col-sm-1" style="position:relative;right:10px;">Visibility</div>
               
            </div>
            </div>
              <div class="categories-list d-flex" style="flex-direction:column" v-for="(category, index) in categories"
                :key="index">
                <div id="top-level" class="col-12 d-flex"
                  style="border-bottom:1px solid #e3e3e3; padding:0.75rem 1.25rem; margin-bottom:0;">
                  <div class="col-sm-1">
                    <p style="font-weight: bold;">{{ category.ID_Grupa }}</p>
                  </div>
                  <div class="col-sm-3">
                    <p>{{ category.name }}</p>
                  </div>
                  <div class="col-sm-3 d-flex">
                    <input style="width: 150px; height:30px;" class="form-control" v-model="category.order"
                      type="number" name="category_order" id="cateogry_order" @focus="handleInputOrderFocus(index)"
                      @blur="handleInputOrderBlur(index)" />
                    <transition name="fade">
                      <button v-if="updateOrderButtonVisibility[index]" @click="
                        updateCategoryOrder(category.ID_Grupa, category.order)
                        " style="height:20px !important;">
                        <i class="uil uil-check-circle"
                          style="font-size:30px;">
                        </i>
                      </button>
                    </transition>
                  </div>
                  <div class="col-sm-3 d-flex">
                    <input style="height:30px;" class="form-control col-sm-8" v-model="category.slug" type="text"
                      name="category_slug" id="cateogr_slug" @focus="handleInputSlugFocus(index)"
                      @blur="handleInputSlugBlur(index)" placeholder="Category slug" />
                    <transition name="fade">
                      <button v-if="updateSlugButtonVisibility[index]" @click="
                        updateCategorySlug(category.ID_Grupa, category.slug)
                        " style="height:20px !important;">
                        <i class="uil uil-check-circle"
                          style="font-size:30px; position:relative; bottom:9px; left:6px;">
                        </i>
                      </button>
                    </transition>
                  </div>

                  <div class="col-sm-1">
                    <input  class="form-check" v-model="category.visibility" type="checkbox" name="category_visibility" id="category_visibility"
                      :checked="category.visibility === 1" @click="updateCategoryVisibility(category.ID_Grupa,category.visibility)" />
                  </div>

                  <div class="col-sm-2">
                    <button @click="toggleDropdown(index)">
                      <i class="fa fa-plus" style="color:blue;"></i>
                    </button>
                  </div>
                </div>
                <!--  DROPDOWN SUB-LEVEL  -->
                <transition name="slide-fade">
                  <div v-if="isDropdownOpen[index]">
                    <div style=" border-bottom:1px solid #e3e3e3;"
                      v-for="(sub_category, subIndex) in category.subcategories" :key="subIndex">
                      <div id="sub-level" style="padding:20px; background-color: #f9f9f9;">
                        <div id="dropdown" class="d-flex col-12">
                          <div class="col-sm-2">
                            <p style="font-weight: bold; position:relative; top:10px;">
                              - {{ sub_category.ID_PodGrupa }}
                            </p>
                          </div>
                          <div class="col-sm-2">
                            <p style="position:relative; top:10px;">
                              {{ sub_category.name }}
                            </p>
                          </div>
                          <div class="col-sm-3 d-flex">
                            <input style="width: 150px; height:30px; position:relative; top:10px;"
                              v-model="sub_category.order" class="form-control" type="number" name="category_order"
                              id="cateogry_order" @focus="handleInputOrderFocus(subIndex)"
                              @blur="handleInputOrderBlur(subIndex)" />
                            <transition name="fade">
                              <button v-if="updateOrderButtonVisibility[subIndex]" @click="
                                updateCategoryOrder(
                                  sub_category.ID_PodGrupa,
                                  sub_category.order
                                )
                                " style="height:20px !important;">
                                <i class="uil uil-check-circle"
                                  style="font-size:30px; position:relative; bottom:1px; left:6px;">
                                </i>
                              </button>
                            </transition>
                          </div>
                          <div class="col-sm-3 d-flex">
                            <input style=" height:30px; position:relative; top:10px;" class="form-control col-sm-7"
                              type="text" v-model="sub_category.category_slug" name="category_slug" id="cateogr_slug"
                              @focus="handleInputSlugFocus(subIndex)" @blur="handleInputSlugBlur(subIndex)"
                              placeholder="Category slug" />
                            <transition name="fade">
                              <button v-if="updateSlugButtonVisibility[subIndex]" @click="
                                updateCategorySlug(
                                  sub_category.ID_PodGrupa,
                                  sub_category.category_slug
                                )
                                " style="height:20px !important;">
                                <i class="uil uil-check-circle"
                                  style="font-size:30px; position:relative; bottom:1px; left:6px;">
                                </i>
                              </button>
                            </transition>
                          </div>
                          <div class="col-sm-1">
                            <input class="form-check" v-model="sub_category.visibility" style="position:relative; position:relative; top:10px;"
                              type="checkbox" name="category_visibility" id="category_visibility"
                              :checked="sub_category.visibility === 1"  @click="updateCategoryVisibility(sub_category.ID_PodGrupa, sub_category.visibility)" />
                          </div>
                          <div style="position:relative; top:10px;" class="col-sm-2"
                            v-if="sub_category.subsubcategories.length !== 0">
                            <button @click="toggleSubSubLevel(subIndex)">
                              <i class="fa fa-plus" style="color:blue;"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <!--  DROPDOWN SUB-SUB LEVEL  -->
                      <transition name="slide-fade">
                        <div id="sub-sub-level" v-if="isSubSubDropdownOpen[subIndex]"
                          style="background-color: #f7f7f7; padding-left:40px; border-top:1px solid #e3e3e3;">
                          <div id="dropdown-subsub-level" class="d-flex align-items-center col-12" v-for="(sub_sub_category,
                            subsubIndex) in sub_category.subsubcategories" :key="subsubIndex">
                            <div class="col-sm-2">
                              <p style="font-weight: bold;">
                                - {{ sub_sub_category.category_id }}
                              </p>
                            </div>
                            <div class="col-sm-3">
                              <p>{{ sub_sub_category.category_name }}</p>
                            </div>
                            <div class="col-sm-3 d-flex">
                              <input style="width: 150px; height:30px;" v-model="sub_sub_category.order"
                                class="form-control" type="number" name="category_order" id="cateogry_order"
                                @focus="handleInputOrderFocus(subsubIndex)" @blur="handleInputOrderBlur(subsubIndex)" />
                              <transition name="fade">
                                <button v-if="
                                  updateOrderButtonVisibility[subsubIndex]
                                " @click="
                                    updateCategoryOrder(
                                      sub_sub_category.category_id,
                                      sub_sub_category.order
                                    )
                                    " style="height:20px !important;">
                                  <i class="uil uil-check-circle"
                                    style="font-size:30px; position:relative; bottom:9px; left:6px;">
                                  </i>
                                </button>
                              </transition>
                            </div>
                            <div class="col-sm-3 d-flex">
                              <input style="width: 150px; height:30px;" v-model="sub_sub_category.category_slug"
                                class="form-control" type="text" name="category_slug" id="category_slug"
                                @focus="handleInputSlugFocus(subsubIndex)" @blur="handleInputSlugBlur(subsubIndex)"
                                placeholder="Category slug" />
                              <transition name="fade">
                                <button v-if="updateSlugButtonVisibility[subsubIndex]" @click="
                                  updateCategorySlug(
                                    sub_sub_category.category_id,
                                    sub_sub_category.category_slug
                                  )
                                  " style="height:20px !important;">
                                  <i class="uil uil-check-circle"
                                    style="font-size:30px; position:relative; bottom:9px; left:6px;">
                                  </i>
                                </button>
                              </transition>
                            </div>
                            <div class="col-sm-1">
                              <input class="form-check" v-model="sub_sub_category.visibility" type="checkbox" name="category_visibility"
                                id="category_visibility" :checked="sub_sub_category.visibility === 1"  @click="updateCategoryVisibility(sub_sub_category.category_id,sub_sub_category.visibility)" />
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-enter-active,
.fade-leave-active {
  animation-duration: 0.5s;
}

.fade-enter-active {
  animation-name: fadeIn;
}

.fade-leave-active {
  animation-name: fadeIn;
  animation-direction: reverse;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
  /* Adjust timing and easing as desired */
}

.slide-fade-enter,
.slide-fade-leave-to {
  /* Element is entering or leaving the DOM */
  transform: translateY(-10px);
  opacity: 0;
}
</style>
