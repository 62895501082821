<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Customers from '@/services/Customers';
    import Cashless from '@/services/Cashless';
    import General from '@/services/General';
    import moment from "moment";
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
    import modalAddCompany from '@/components/modals/customers/modalAddCompany';
    import _ from 'underscore';

    export default {
        components: { Layout, PageHeader,  VueBootstrapTypeahead , modalAddCompany },
        page: {
            title: "Companies",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
         data() {
            return {
                isAuthorized: false,
                error: null,
                filterData: false,
                showMapData: false,
                showMap: false,
                tableData: [],
                title: "Companies",
                items: [
                    {
                        text: "CRM",
                        href: "/customers/companies",
                    },
                    {
                        text: "Companies",
                        active: true,
                    },
                ],
                ticketing_client_types:[],
                userStatuses: [],
                filterInput: {
                    name: '',
                    gender: [],
                    company_id: '',
                    userStatus: [],
                },
                companyData: [],
                countries: [],
                isBusy: false,
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "created_on",
                sortDesc: true,
                fields: [
                    {
                        key: "name",
                        label: "Company Name",
                        sortable: true,
                    },
                    {
                        key: "company_id",
                        label: "Company Id",
                        sortable: true,
                    },
                    {
                        key: "website",
                        label: "Website",
                        sortable: true,
                    },
                    {
                        key: "logo_url",
                        label: "Logo Url",
                        sortable: true,
                    },
                    "action"
                ],
            };
        },

        methods: {

            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            modalAddCompany(){
                this.$bvModal.show("add_new_company");
            },

            async getCompanies(){
                try {
                    this.filterData = true;
                    this.toggleBusy();
                    var filters = this.getFilters()
                    const response = await Customers.getCompanies(filters)
                    this.tableData = response.data.data
                    this.totalRows = this.tableData.length
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                    this.totalRows = 0;
                } finally {
                    this.toggleBusy();
                }
            },
            getFilters(){
                var filter_string = '?limit=10000';
                filter_string += (this.filterInput.name) ? '&name='+this.filterInput.name : "";
                filter_string += (this.filterInput.company_id) ? '&company_id='+this.filterInput.company_id : "";
                return filter_string = filter_string.substring(1);
            },


             },
        mounted() {
            this.getCompanies();
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">

                        <div class="row mt-4">
                            <div class="col-md-12">
                                <div class="custom-accordion">
                                    <a
                                        class="text-body fw-semibold pb-2 d-block"
                                        data-toggle="collapse"
                                        href="javascript: void(0);"
                                        role="button"
                                        aria-expanded="false"
                                        v-b-toggle.categories-collapse
                                    >
                                        <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
                                    </a>
                                    <b-collapse visible id="categories-collapse">
                                        <div class="card p-2 border shadow-none">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-3">
                                                    <b-form-group label="Company name" label-for="formrow-name-input" class="mb-3">
                                                        <vue-bootstrap-typeahead id="formrow-name-input" ref="nameAutocomplete"
                                                                                 v-model.trim="filterInput.name"
                                                                                 :data=companyData
                                                                                 :serializer="s => s.text"/>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-sm-12 col-md-3">
                                                    <div class="form-group mb-3">
                                                        <b-form-group label="Company Id" label-for="formrow-company_id-input" class="mb-3">
                                                            <vue-bootstrap-typeahead id="formrow-company_id-input" ref="company_idAutocomplete"
                                                                                     v-model.trim="filterInput.company_id"
                                                                                     :data=companyData
                                                                                     :serializer="s => s.text"/>
                                                        </b-form-group>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row mt-3">
                                                <div class="col d-inline-flex">
                                                    <button type="button" class="btn btn-success me-2 w-lg" @click="getCompanies">Filter</button>
                                                    <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
                                                </div>
                                            </div>

                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="card" v-show="filterData">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col d-inline-flex align-items-center" >
                                <h4 class="card-title m-0">Total Companies: {{totalRows}}</h4>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="mb-3">
                                <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light me-3"
                                   @click="modalAddCompany">
                                    <i class="mdi mdi-plus me-2"></i>
                                    Create New
                                </a>
                            </div>
                        </div>

                        <div class="row mt-4" v-show="filterData">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Search..."
                                            class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0" v-show="filterData">
                            <b-table
                                :items="tableData"
                                :busy="isBusy"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                                show-empty
                                empty-text="No Data Found"
                            >
                               
                                <template v-slot:cell(company_id)="data">
                                    {{ data.item.company_id }}
                                </template>

                                <template v-slot:cell(website)="data">
                                    <a :href="data.item.website" class="nav-link">
                                        <span>{{data.item.website}}</span>
                                    </a>
                                </template>
                                <template v-slot:cell(logo_url)="data">
                                    <img :src="data.item.logo_url" style="width: 80px;" />
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>
                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <router-link title="View Company" :to="{ path: `/customers/companies/${data.item.company_id}`}">
                                                <i class="uil uil-eye font-size-18"></i>
                                            </router-link>
                                        </li>
                                    </ul>
                                </template>
                            </b-table>
                        </div>
                        <div class="row" v-show="filterData">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modalAddCompany @onRefresh="getCompanies()"></modalAddCompany>

    </Layout>
</template>
