<script>
import ServiceTicketService from "@/services/ServiceTickets";

export default {
  props: ['ticketDetails'], // Pass the ticket details as a prop
  data() {
    return {
      editableTicket: {}, // Local copy for editing
    };
  },
  watch: {
    ticketDetails: {
      handler(newVal) {
        this.editableTicket = { ...newVal };
      },
      immediate: true,
    },
  },
  methods: {
    async saveChanges() {
      const payload = {
        status: this.editableTicket.status,
      };

      try {
        const response = await ServiceTicketService.updateServiceTicket(this.ticketDetails.ticket_id, JSON.stringify(payload));
        if (response.status >= 200 && response.status < 300) {
          // Emit event for parent to update after save
          this.$emit('update-success', this.editableTicket);
          this.$emit('refreshTable');  // Emit refreshTable only after saving
          this.$bvModal.hide('status_service_ticket');  // Close the modal after save
          window.location.reload();

        } else {
          throw new Error('Failed to save changes');
        }
      } catch (error) {
        console.error("Error updating ticket:", error);
        alert("Error saving changes. Please try again.");
      }
    },

    async deleteTicket() {
      try {
        const response = await ServiceTicketService.deleteServiceTicket(this.ticketDetails.ticket_id);
        const data = response.data;

        if (response.status >= 200 && response.status < 300) {
          // Emit an event to the parent to refresh the table
          this.$emit('refreshTable');
          this.closeModal();  // Close the modal after successful deletion
        } else {
          console.error('Error deleting ticket:', data);
        }
      } catch (error) {
        console.error('Error deleting ticket:', error);
      }
    },

    closeModal() {
      // Only hide the modal, no page reload or table refresh here
      this.$bvModal.hide('status_service_ticket');
    },
  }

};
</script>

<template>
  <b-modal id="status_service_ticket" title="Edit Service Ticket" @hidden="closeModal" size="xl" centered>
    <template v-slot:default>
      <div class="row">
        <div class="col-sm-6">
          <b-form-group label="Status">
            <b-form-select v-model="editableTicket.status"  :options="[
        { value: 0, text: '0-Сервис тикетот е одбиен' },
        { value: 1, text: '1-Нов сервис тикет' },
        { value: 2, text: '2-Сервис тикетот е отворен' },
        { value: 3, text: '3-Уредот е подигнат за сервис' },
        { value: 4, text: '4-Уредот се сервисира' },
        { value: 5, text: '5-Уредот е сервисиран' },
        { value: 6, text: '6-Уредот чека на испорака' },
        { value: 7, text: '7-Уредот е доставен' }
      
    ]"  />
          </b-form-group>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Cancel</b-button>
      <b-button variant="primary" @click="saveChanges">Save Changes</b-button>
    </template>
  </b-modal>
</template>
