<script>
import ServiceTicketService from "@/services/ServiceTickets";
import AuthService from "@/services/Auth";

export default {
  props: ['ticket_id', 'value'],
  data() {
    return {
      ticketDetails: null, // Stores the ticket details
      ticketHistory:null, // Store ticket history
      historyUsers:null, // Store users
      mergedHistory:null, // ticket history with username
      showLoader: false,
      statusOptions: {
        0: '0-Сервис тикетот е одбиен',
        1: '1-Нов сервис тикет',
        2: '2-Сервис тикетот е отворен',
        3: '3-Уредот е подигнат за сервис',
        4: '4-Уредот се сервисира',
        5: '5-Уредот е сервисиран',
        6: '6-Уредот чека на испорака',
        7: '7-Уредот е доставен'
      }
    };
  },
  computed: {
    showModal: {
      get() {
        return this.value; // Sync with parent
      },
      set(val) {
        this.$emit('input', val); // Update parent
      },
    },
    ticketStatus() {
      return this.statusOptions[this.ticketDetails.status] || 'Unknown status';
    },
    
  },
  methods: {
    async getTicketDetails() {
      this.showLoader = true;
      try {
        const response = await ServiceTicketService.getServiceTicket(this.ticket_id);
        const data = response.data;
        this.ticketDetails = data[0]; // Bind ticket data
        
        
        const history = await ServiceTicketService.getServiceTicketHistory(this.ticket_id)
        this.ticketHistory = history.data.data;

          if (this.ticketHistory && this.ticketHistory.length > 0) {
            const userPromises = this.ticketHistory.map(entry =>
              AuthService.getUsers(`id=${entry.admin_id}`).then(res => res.data)
            );
            this.historyUsers = await Promise.all(userPromises);

            const merged = this.ticketHistory.map(historyItem => {
              const matchedUser = this.historyUsers.find(
                u => u.users[0].id === historyItem.admin_id
              );
              return {
                ...historyItem,
                admin_username: matchedUser
                  ? matchedUser.users[0].username
                  : "Unknown"
              };
            });
            this.mergedHistory = merged;
          }
      } catch (error) {
        console.error("Failed to fetch service ticket details:", error);
        this.ticketDetails = null;
      } finally {
        this.showLoader = false;
      }
    },
    
    resetProps() {
      // Clear data when modal is hidden
      this.ticketDetails = null; 
      this.ticketHistory = null;
      this.historyUsers = null;
      this.mergedHistory = null;
    },
    ticketHistoryStatus(status) {
    return this.statusOptions[status] || 'Unknown status';
    },
    
  },
};
</script>

<template>
  <b-modal
      @shown="getTicketDetails"
      @hidden="resetProps"
      v-model="showModal"
      id="view_service_ticket"
      title="View Service Ticket"
      title-class="font-18"
      size="xl"
      centered
  >
    <template v-if="!showLoader && ticketDetails">
      <div class="row">
        <div class="col-sm-3">
          <p><strong>First Name:</strong> {{ ticketDetails.first_name }}</p>
          <p><strong>Last Name:</strong> {{ ticketDetails.last_name }}</p>
          <p><strong>Guarantee:</strong> {{ ticketDetails.guarantee === 1 ? 'Yes' : 'No' }}</p>
          <p><strong>Company Name:</strong> {{ ticketDetails.company_name }}</p>
          <p><strong>Phone:</strong> {{ ticketDetails.phone }}</p>
          <p><strong>Description:</strong> {{ ticketDetails.description }}</p>
        </div>
        <div class="col-sm-4">
          <p><strong>Email:</strong> {{ ticketDetails.email }}</p>
          <p><strong>City:</strong> {{ ticketDetails.city }}</p>
          <p><strong>Address:</strong> {{ ticketDetails.address }}</p>
          <p><strong>Product Model:</strong> {{ ticketDetails.product_model }}</p>
          <p><strong>Product Serial:</strong> {{ ticketDetails.product_serial }}</p>
          <p style="word-wrap: break-word;"><strong>Fiscal Slip:</strong>
          <a :href="ticketDetails.fiscal_slip" target="_blank">{{ticketDetails.fiscal_slip }}</a></p>
          <p style="word-wrap: break-word;">
            <strong>Guarantee Scan:</strong>
            <a v-if="ticketDetails.guarantee_list_scan" :href="ticketDetails.guarantee_list_scan" target="_blank">
              {{ ticketDetails.guarantee_list_scan }}
            </a>
            <span v-else>Not Uploaded</span>
          </p>
          <p><strong>Status:</strong> {{ ticketStatus }}</p>
          <p><strong>Created:</strong> {{ ticketDetails.created}}</p>
          <p><strong>Modified:</strong> {{ ticketDetails.timestamp}}</p>
        </div>
        <div class="col-sm-4">
          <h4>Ticket History</h4>
          
          <div v-if="ticketHistory && ticketHistory.length > 0" class="scrollable-history">
            <div style="border-bottom:1px dotted black" class="col-sm-12" v-for="(ticket, index) in mergedHistory" :key="index">
                     <!-- <p>Ticket ID: {{ ticket.ticket_id }}</p> -->
                     <p>{{ ticket.timestamp }}</p>
                     <p>{{ ticket.admin_username }} ( {{ ticket.action }} )</p>
                     <p>{{ ticketHistoryStatus(ticket.status) }}</p>
            </div>
          </div>
           <p v-else>No ticket history.</p>
        </div>
        
      </div>
 
    </template>
    <template v-else-if="showLoader">
      <div class="d-flex justify-content-center">
        <b-spinner large></b-spinner>
      </div>
    </template>
    <template v-else>
      <div class="text-center text-muted">No ticket details available.</div>
    </template>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="showModal = false">Close</b-button>
    </template>
  </b-modal>
</template>
<style>
.scrollable-history {
  max-height: 300px; 
  overflow-y: auto;  
  padding: 0.5rem; 
}
</style>