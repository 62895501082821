<script>
    import { required, minLength } from "vuelidate/lib/validators";
    import Content from '@/services/Content';
    import Swal from "sweetalert2";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import validationMessages from '@/components/validations'

    export default {
        components:{
            flatPickr,
            validationMessages, ckeditor: CKEditor.component,
        },
        props: {
            data: {
                type: Object,
                required: true
            },
        },
     
watch: {
    data: function (newVal) {
        console.log('New data received:', newVal);
        if (newVal) {
            this.news_id = newVal.id || "";
            this.title = newVal.title || "";
            this.original_content = newVal.original_content || ""; 
            this.content = newVal.content || ""; 
            this.published = newVal.published || "";
            this.image = newVal.image || "";
            this.content_type = newVal.content_type || "news";
        }
    },
    original_content: function (newVal) {
        this.content = newVal; 
    }
},


        data() {
            return {
                news_id:"",
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                title: "",
                content: "",
                original_content:"",
                published: "",
                image: "",
                file: null,
                content_type: "news",
                datetimeconfig: {
                    enableTime: true,
                    time_24hr: true,
                    minuteIncrement: 1,
                    inline: true,
                },
                editor: ClassicEditor,
                editorData: '',
                csrf_token: localStorage.getItem('csrf_token')
            };
        },
        validations: {
            title: {
                required,
                minLength: minLength(5)
            },
            original_content: {
                required
            },
            published: { required },
        },
        methods: {

            async editBusinessNews() {

  this.tryingToEdit = true;
  this.$v.$touch();

  if (this.$v.$invalid) {
    this.tryingToEdit = false;
    console.error("Validation errors:", this.$v.$errors);
    this.submitted = true; 
    return;
  }
  this.csrf_token = localStorage.getItem('csrf_token') || '';
  const formData = new FormData();
  formData.append("title", this.title);
  const modifiedContent = this.convertHtmlToJson(this.content); 
  formData.append("content", modifiedContent); 
  formData.append("original_content", this.original_content); 
  formData.append('_method', 'PATCH');

  if (this.file) {
    formData.append("image", this.file);
  }
  formData.append("published", this.published);
  formData.append("csrf_token", this.csrf_token);
  formData.append("content_type", this.content_type);

  try {
    const response = await Content.editBusinessNews(this.news_id, formData);
    if (response && !response.error) {
      this.successmsg("Business News successfully updated");
      this.closeModal();
      this.refreshData(); 
    } else {
      console.error("Update failed:", response.error);
      this.failedmsg(response.error);
    }
  } catch (error) {
    console.error("Error updating business news:", error);
    this.failedmsg("Error updating Business News");
  } finally {
    this.tryingToEdit = false; 
  }
},
convertHtmlToJson(htmlContent) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const articleContent = [];

        doc.body.childNodes.forEach(node => {
            if (node.nodeType === Node.ELEMENT_NODE) {
                articleContent.push({
                    type: node.tagName.toLowerCase(),
                    text: node.innerText
                });
            }
        });

        return JSON.stringify({
            articleContent: articleContent,
            articleLinks: [],
            articleImages: []
        });
    },

refreshData() {
                this.$emit('onRefresh') //event from parent
             },
           
      selectFile(event) {
      if (this.image) {
        URL.revokeObjectURL(this.image);
      }
      const file = event.target.files[0];
      this.file = file;
      this.image = URL.createObjectURL(file);
    },
    removeImage() {
      this.file = null;
      this.image = "";
      this.$refs["file-input"].reset();
    },
    resetProps() {
      this.submitted = false;
      this.title = "";
      this.content = "";
      this.original_content = "";
      this.published = "";
      this.image = "";
      this.file = null;
    },
    populateFields() {
        if (this.data) {
            this.news_id = this.data.id || "";
            this.title = this.data.title || "";
            this.original_content = this.data.original_content || ""; 
            this.content = this.data.content || ""; 
            this.published = this.data.published || "";
            this.image = this.data.image || "";
            this.content_type = this.data.content_type || "news";
        }
    },

            closeModal() {
                this.showModal = false;
                this.resetProps();
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },

        }
    };


</script>

<template>
    <b-modal
      id="news_edit"
      size="lg"
      v-model="showModal"
      @hidden="resetProps"
      @shown="populateFields"
      title="Edit Business News"
      title-class="font-18"
    >
      <form @submit.prevent="editBusinessNews">
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Title" label-for="title" class="mb-3">
              <b-form-input
                v-model="title"
                id="title"
                :class="{ 'is-invalid': submitted && $v.title.$error }"
              ></b-form-input>
              <validationMessages
                v-if="submitted"
                :fieldName="'Title'"
                :validationName="$v.title"
              ></validationMessages>
            </b-form-group>
  
            <b-form-group label="Upload Image" label-for="image" class="mb-3 image-area">
              <img
                :src="image || require('@/assets/images/image_not_found.png')"
                alt=""
                width="150"
                class="img-thumbnail me-2"
              />
              <b-form-file
                @change="selectFile"
                ref="file-input"
                type="file"
                class="mt-3"
                plain
                accept="image/jpeg, image/png"
              ></b-form-file>
              <a
                v-if="image"
                class="remove-image"
                href="javascript:void(0);"
                @click="removeImage"
                style="display: inline;"
              >
                &#215;
              </a>
            </b-form-group>
  
            <b-form-group label="Content" label-for="content" class="mb-3">
              <ckeditor :editor="editor" v-model="original_content"></ckeditor>
              <validationMessages
                v-if="submitted"
                :fieldName="'Content'"
                :validationName="$v.original_content"
              ></validationMessages>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Published Date" label-for="published" class="mb-3">
              <flat-pickr
                v-model="published"
                :config="datetimeconfig"
                class="form-control mb-2"
                name="published"
              />
              <validationMessages
                v-if="submitted"
                :fieldName="'Published'"
                :validationName="$v.published"
              ></validationMessages>
            </b-form-group>
          </div>
        </div>
        <input type="hidden" name="content_type" v-model="content_type" />
        <input type="hidden" name="csrf_token" v-model="csrf_token" />
      </form>
  
      <template v-slot:modal-footer>
        <b-button variant="light" @click="closeModal">Close</b-button>
        <b-button variant="primary" @click="editBusinessNews" :disabled="tryingToEdit">
          <b-spinner v-show="tryingToEdit" small></b-spinner>
          Submit
        </b-button>
      </template>
    </b-modal>
  </template>