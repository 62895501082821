
import Http from './Http'

export default {
    async getServiceTickets(data = '') {
        return await Http.get(`/merchandise/serviceTicket?${data}`)
    },
    async getCity(data = '') {
        return await Http.get(`/customers/city?${data}`)
    },
    async getServiceTicket(guid) {
        return await Http.get(`/merchandise/serviceTicket/${guid}`)
    },
    async updateServiceTicket(guid,data) {
        return await Http.put(`/merchandise/serviceTicket/${guid}`, data)
    },
    async deleteServiceTicket(guid){
        return await Http.delete(`/merchandise/serviceTicket/${guid}`)
    }

}


