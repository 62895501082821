<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Merchandising from "@/services/Merchandising";
import Http from "@/services/Http";
import Swal from "sweetalert2";
//    import TabProductTags from "@/components/tabs/tabProductTags";
import TabProductPromotions from "@/components/tabs/tabProductPromotions";
import TabProductRelated from "@/components/tabs/tabProductRelated";
/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader , TabProductPromotions , TabProductRelated },
  page: {
    title: "Brand details",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Brand details",
      items: [
        {
          text: "Brands",
          href: "/",
        },
      ],
      showFullDescription: false,
      descLines: [],
      isCollapsed: true,
      filterData: false,
      isBusy: false,
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "Art_Sifra",
      product_id: this.$route.params.id,
      sortDesc: true,
      brandInfo: {},
    };
  },
  middleware: "authentication",

  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newProductId) {
        this.product_id = newProductId;
        this.getBrand();
      }
    }
  },
  mounted() {
  },
  async created() {
    this.getBrand();
  },
  methods: {

    async getBrand() {
      try {
        this.toggleBusy();
        let query = `brand_id=${this.product_id}`;
        const response = await Merchandising.getMerchandiseBrand(query);
        this.brandInfo = response.data.data.data; // Updated to match the structure in your response

        // Log the logo_img property
        console.log(this.brandInfo.logo_img);

        this.toggleBusy();
      } catch (error) {
        this.error = error.response?.data?.error || "An unknown error occurred";
        this.tableData = [];
      }
    },


//        async getTags() {
//          try {
//            this.toggleBusy();
//            const response = await Merchandising.getTags()
//            this.tags = response.data.data
//            this.toggleBusy();
//          } catch (error) {
//            this.error = error.response.data.error ? error.response.data.error : "";
//          }
//        },

    refreshTable() { },

  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row mb-4 p-3">
      <div class="col-xl-4">
        <div class="card mh-50">
          <div class="card-body">
            <div class="text-center">
              <div class="d-flex justify-content-center">
                <img
                    :src="brandInfo.logo_img || 'https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-6.png'"
                    class="card-img-top d-block w-50 h-50"
                    alt="Brand Logo"
                />
              </div>
            </div>

            <hr class="my-2" />

            <div class="table-responsive mt-4 mb-0">
              <h5 class="text-primary ">Brand Information</h5>
              <div class="mt-2">
                <p class="">
                  <span class="text-strong font-size-15">Brand Name : </span>
                  <span class="font-size-14">{{ brandInfo.brand_code }}</span>
                </p>
              </div>
              <hr>
              <div class="">
                <p class="mb-2">
                  <span class="text-strong font-size-15">Category : </span>
                  <span class="font-size-14">{{ brandInfo.Grupa }} </span>
                </p>
              </div>

              <div class="mt-2">
                <p class="mb-2">
                  <span class="text-strong font-size-15">Brand : </span>
                  <span class="font-size-14">{{ brandInfo.Brend }}</span>
                </p>
              </div>
              <div class="mt-2">
                <p class="mb-2">
                  <span class="text-strong font-size-15">Warranty : </span>
                  <span class="font-size-14" v-if="brandInfo.Garancija > 0">{{ brandInfo.Garancija }} months</span>
                  <span class="font-size-14" v-else>No Warranty</span>
                </p>
              </div>
              <div class="mt-2">
                <p class="mb-2 font-size-15">
                  <span class="text-strong font-size-15">Regular Price : </span>
                  <span class="font-size-14">}</span>
                </p>
              </div>
              <div class="mt-2">
                <p class="mb-2 font-size-15">
                  <span class="text-strong font-size-15">Club Price : </span>
                  <span class="text-primary font-size-14"></span>
                </p>
              </div>

              <div class="mt-2 d-flex justify-content-end ">
                <p class="mb-2 font-size-18">
                  <span class="text-strong font-size-15 ">Stock : </span>
                  <span class="text-strong font-size-14">{{ brandInfo.Lager_Kolicina }}</span>
                </p>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="card mb-0">
          <b-tabs content-class="p-4" justified class="nav-tabs-custom">
            <b-tab active>
              <template v-slot:title>
                <i class="uil uil-list-ui-alt font-size-20"></i>
                <span class="d-none d-sm-block">Description</span>
              </template>

            </b-tab>

            <b-tab>
              <template v-slot:title>
                <i class="uil uil-ticket font-size-20"></i>
                <span class="d-none d-sm-block">Promotions</span>
              </template>
              <div>
                <div class="row">
                  <TabProductPromotions :product_id=this.product_id />
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <i class="uil uil-ticket font-size-20"></i>
                <span class="d-none d-sm-block">Related Products</span>
              </template>
              <div>
                <div class="row">
                  <TabProductRelated :product_id=this.product_id />
                </div>
              </div>
            </b-tab>

          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>