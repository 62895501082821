<script>
    import { required } from "vuelidate/lib/validators";
    import Customers from '@/services/Customers';
    import validationMessages from '@/components/validations'
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";

    export default {
        components: {
            validationMessages,  vueDropzone: vue2Dropzone,
        },
        props: {

                modalData: {
                    type: Object,
                    default: () => ({
                        company_id: '',
                        name: '',
                        description: '',
                        website: '',
                        logo_url: '',
                        company_image: '',
                        background_color: '',
                        club_members: [] ,
//                        verified_checked : false ,
                    }),
                },
        },
        watch: {
            modalData: {
                immediate: true,
                deep: true,
                handler(newVal) {
                    if (newVal) {
                        this.company_id = newVal.company_id || '';
                        this.name = newVal.name || '';
                        this.website = newVal.website || '';
                        this.description = newVal.description || '';
                        this.background_color = newVal.background_color || '';
                        this.company_image = newVal.company_image || '';
                        this.logo_url = newVal.logo_url || '';
                        this.photoDeleted = false;
                        this.LogoUrlDeleted = false;
                    }
                },
            },
        },
        validations: {
            name: {
                required
            },
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                id_e: '',
                company_id: '' ,
                name: '',
                website: '',
                description: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                photoDeleted: false,
                LogoUrlDeleted : false ,
                active : false ,
                dropzoneOptions_company_image: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles: 'image/*',
                    maxFiles: 1,
                },
                dropzoneOptions_logo_url: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles: 'image/*',
                    maxFiles: 1,
                },
            };
        },
        methods: {
            refreshData() {
                this.$emit('onRefresh')
            },
            closeModal() {
                this.showModal = false;
                this.resetProps();
                this.photoDeleted = false;
                this.LogoUrlDeleted = false;
            },
            resetProps() {
                this.submitted = false;
                this.name = '';
                this.website = '';
                this.description = '';
            },
            selectFileHome(files) {
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_company_image.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only select one image');

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_company_image.removeFile(value);
                        });
                    }
                    this.company_image = queuedFiles[0];
                    this.photoDeletedHome = false;
                }, 250);
            } ,
            selectLogoUrl(files) {
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_logo_url.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only select one image');

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_logo_url.removeFile(value);
                        });
                    }
                    this.logo_url = queuedFiles[0];
                    this.photoDeleted = false;
                }, 250);
            } ,

            deleteImage() {
                this.photoDeleted = true;
                this.company_image = '';
            },

            deleteLogoUrl() {
                this.LogoUrlDeleted = true;
                this.logo_url = '';
            },

            async editCompany(){
                this.tryingToEdit = true;
                this.submitted = true;

                    try {

                        const company_id =  this.company_id;

                        const data = {
                            name: this.name,
                            company_id: this.company_id,
                            description: this.description,
                            website: this.website,
                            company_image: this.company_image,
                            logo_url: this.logo_url,
                            background_color: this.background_color,
                            csrf_token: this.csrf_token,
                            _method :  'PATCH'

                    };
                        await Customers.editCompany(company_id,data).then((response) => {
                            this.successmsg("Changes has been saved");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }

                this.tryingToEdit = false;
            },
        }
    };</script>

<template>
    <b-modal @hidden="resetProps" v-model="showModal" id="company_edit" title="Edit Customer" title-class="font-18" centered>
        <form @submit.prevent="editCompany">
            <input style="display:none" v-model.trim="modalData.company_id" >
            <b-form-group label="Company Name" label-for="formrow-company-name-input" class="mb-3">
                <b-form-input
                        v-model.trim="modalData.name"
                        type="text"
                        id="name"
                        aria-describedby="name-feedback"
                        :class="{
                            'is-invalid': submitted && $v.name.$error,
                        }"
                ></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Company Name'" :validationName="$v.name"></validationMessages>
            </b-form-group>
            <b-form-group label="Description" label-for="formrow-description-input" class="mb-3">
                <b-form-input
                        v-model.trim="description"
                        type="text"
                        id="description"
                        aria-describedby="description-feedback"
                ></b-form-input>
            </b-form-group>
            <b-form-group label="Website" label-for="formrow-website-input" class="mb-3">
                <b-form-input
                        v-model.trim="website"
                        type="text"
                        id="website"
                        aria-describedby="website-feedback"
                ></b-form-input>
            </b-form-group>
            <b-form-group label="Background Color" label-for="formrow-background-color-input" class="mb-3">
                <b-form-input
                        v-model.trim="background_color"
                        type="text"
                        id="background_color"
                        aria-describedby="website-feedback"
                ></b-form-input>
            </b-form-group>
            <!--<b-form-checkbox-group>-->
                <!--<div class="form-check form-switch form-switch-lg mb-3" >-->
                    <!--<input v-model="verified_checked"-->
                           <!--type="checkbox"-->
                           <!--class="form-check-input"-->
                           <!--id="companyStatus"-->
                    <!--/>-->
                    <!--<label class="form-check-label" for="companyStatus">Active</label>-->
                <!--</div>-->
            <!--</b-form-checkbox-group>-->

            <div class="form-group " >
                <label for="company_image">Company Image:</label>
                <template v-if="!photoDeleted && company_image">
                    <b-form-group label-for="image" class="mb-3 text-center image-area">
                        <img :src="company_image" alt="" width="200" class="img-thumbnail" />
                    </b-form-group>
                    <div class="text-center mt-3">
                        <a class="btn text-danger" v-b-tooltip.hover title="Delete Image" @click="deleteImage">
                            <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                    </div>
                </template>
                <template v-else>
                    <vue-dropzone
                            id="dropzone_company_image"
                            ref="myVueDropzone_company_image"
                            :use-custom-slot="true"
                            :options="dropzoneOptions_company_image"
                            @vdropzone-files-added="selectFileHome"
                            no-progress-bar
                    >
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop file here or click to upload.</h4>
                        </div>
                    </vue-dropzone>
                </template>
            </div>
            <div class="form-group " >
                <label for="logo_url">Logo Url:</label>
                <template v-if="!LogoUrlDeleted && logo_url">
                    <b-form-group label-for="image" class="mb-3 text-center image-area">
                        <img :src="logo_url" alt="" width="200" class="img-thumbnail" />
                    </b-form-group>
                    <div class="text-center mt-3">
                        <a class="btn text-danger" v-b-tooltip.hover title="Delete Image" @click="deleteLogoUrl">
                            <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                    </div>
                </template>
                <template v-else>
                    <vue-dropzone
                            id="dropzone_logo_url"
                            ref="myVueDropzone_logo_url"
                            :use-custom-slot="true"
                            :options="dropzoneOptions_logo_url"
                            @vdropzone-files-added="selectLogoUrl"
                            no-progress-bar
                    >
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop file here or click to upload.</h4>
                        </div>
                    </vue-dropzone>
                </template>
            </div>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editCompany" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>

        </template>
    </b-modal>
</template>
