<script>
import Swal from "sweetalert2";
import brands from "../../../views/pages/merchandising/brands.vue";
import Merchandising from "@/services/Merchandising";
import vueDropzone from "vue2-dropzone";


export default {
  components: {vueDropzone},
  computed: {
    brands() {
      return brands
    }
  },
  props: ['brandDetails'],
  data() {
    return {
      editableBrand: {},
      brand_id: null,
      brand_name: '',
      brand_code: '',
      display_order: '',
      logo_img: null,
      header_image: null,
      tryingToSubmit: false,
      showLoader: false,
      dropzoneOptions_logo: {
        url: "/",
        autoProcessQueue: false,
        addRemoveLinks: false,
        thumbnailWidth: null,
        thumbnailHeight: null,
        thumbnailMethod: "contain",
        maxFilesize: 2,
        acceptedFiles: "image/*",
        maxFiles: 1,

      },
      dropzoneOptions_header: {
        url: "/",
        autoProcessQueue: false,
        addRemoveLinks: false,
        thumbnailWidth: null, // Adjust as needed
        thumbnailHeight: null, // Adjust to match container aspect ratio
        thumbnailMethod: "contain",
        maxFilesize: 2,
        acceptedFiles: "image/*",
        maxFiles: 1,

      },
      productsBrands: '',
    };
  },
  watch: {
    brandDetails: {
      handler(newVal) {
        this.editableBrand = { ...newVal };
      },
      immediate: true,
    },
  },
  methods: {
    async saveChanges() {
      this.tryingToSubmit = true;
      this.showLoader = true;

      let formData = new FormData();
      formData.append('brand_id', this.editableBrand.brand_id);
      formData.append('brand_name', this.editableBrand.brand_name);
      formData.append('brand_code', this.editableBrand.brand_code);
      formData.append('display_order', this.editableBrand.display_order);


      // Append files to FormData
      const logoFile = this.$refs.myVueDropzone_logo.getAcceptedFiles()[0];
      if (logoFile) {
        formData.append('logo_img', logoFile);
      }else{
        formData.append('logo_img', this.editableBrand.logo_img);
      }

      const headerFile = this.$refs.myVueDropzone_header.getAcceptedFiles()[0];
      if (headerFile) {
        formData.append('header_image', headerFile);
      }else{
        formData.append('header_image', this.editableBrand.header_image);
      }

      try {
        await Merchandising.editMerchandiseBrand(formData);
        Swal.fire("Success", "Brand changed successfully!", "success");
        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.error("Failed to create brand:", error);
        Swal.fire("Error", "Failed to changed brand. Please try again.", "error");
      } finally {
        this.tryingToSubmit = false;
        this.showLoader = false;
      }
    },

    refreshData() {
      this.$emit('onRefresh')
    },


    async getBrands() {
      this.showLoader = true;
      try {
        const limit = "limit=10000";
        const response = await Merchandising.getProducts(limit);
        const products = response.data.data.products;

        const brands = [
          ...new Map(
              products
                  .filter((product) => product.Art_Proizvoditel?.trim())
                  .map((product) => [
                    product.Art_Proizvoditel,
                    {value: product.Art_Proizvoditel, text: product.Art_Proizvoditel},
                  ])
          ).values(),
        ];
        this.productsBrands = brands.sort((a, b) => a.text.localeCompare(b.text));
      } catch (error) {
        console.error("Error fetching brands:", error);
        Swal.fire("Error", "Failed to fetch brands. Please try again.", "error");
      } finally {
        this.showLoader = false;
      }
    },
    closeModal() {
      this.$bvModal.hide('edit_service_ticket');
    },
    setData() {
      this.getBrands()
      this.preloadDropzone()
      this.preloadDropzone1()

    },
    preloadDropzone() {
      // Access Dropzone instance via the ref
      const dropzone = this.$refs.myVueDropzone_logo.dropzone;

      // Clear existing files in Dropzone (if any)
      dropzone.removeAllFiles(true);

      const fileUrl = this.editableBrand.logo_img;
      if (fileUrl) {
        // Create a mock file object
        const mockFile = {
          name: "Logo Image", // You can customize this
          size: 12345, // Mock size; not used but required
          type: "image/png", // Adjust MIME type as necessary
        };

        // Add the mock file to Dropzone
        dropzone.emit("addedfile", mockFile);
        dropzone.emit("thumbnail", mockFile, fileUrl);
        dropzone.emit("complete", mockFile);

        // Optionally, set the file as accepted (if needed for your logic)
        mockFile.status = dropzone.ADDED;
        dropzone.files.push(mockFile);
      }
    },
    preloadDropzone1() {
      // Access Dropzone instance via the ref
      const dropzone = this.$refs.myVueDropzone_header.dropzone;

      // Clear existing files in Dropzone (if any)
      dropzone.removeAllFiles(true);

      const fileUrl = this.editableBrand.header_image;
      if (fileUrl) {
        // Create a mock file object
        const mockFile = {
          name: "Header Image", // You can customize this
          size: 12345, // Mock size; not used but required
          type: "image/*", // Adjust MIME type as necessary
        };

        // Add the mock file to Dropzone
        dropzone.emit("addedfile", mockFile);
        dropzone.emit("thumbnail", mockFile, fileUrl);
        dropzone.emit("complete", mockFile);

        // Optionally, set the file as accepted (if needed for your logic)
        mockFile.status = dropzone.ADDED;
        dropzone.files.push(mockFile);
      }
    },
    removeFile() {
      const dropzoneInstance = this.$refs.myVueDropzone_logo.dropzone;

      if (dropzoneInstance && dropzoneInstance.files.length > 0) {
        // Remove the first file (or loop to find a specific file if needed)
        const fileToRemove = dropzoneInstance.files[0]; // Replace with the target file if needed
        dropzoneInstance.removeFile(fileToRemove);

        this.editableBrand.logo_img = null;
      } else {
        console.warn("No files to remove or Dropzone is not initialized.");
      }
    },
    removeFile1() {
      const dropzoneInstance = this.$refs.myVueDropzone_header.dropzone;

      if (dropzoneInstance && dropzoneInstance.files.length > 0) {

        const fileToRemove = dropzoneInstance.files[0]; // Replace with the target file if needed
        dropzoneInstance.removeFile(fileToRemove);
        this.editableBrand.header_image = null;
      } else {
        console.warn("No files to remove or Dropzone is not initialized.");
      }
    },
  }
};
</script>

<template>
  <b-modal id="edit_service_ticket" title="Edit Merchandise Brand" @hidden="closeModal" size="l"  @shown="setData" centered>
    <template v-slot:default>
      <div class="row">
        <div class="col-sm-12">
          <b-form-group label="Brand Name">
            <b-form-input v-model="editableBrand.brand_name" />
          </b-form-group>
          <b-form-group label="Origin Brand Name" class="mb-3">
            <b-form-select
                id="brands-select"
                :value="brands"
                v-model="editableBrand.brand_code"
                :options="productsBrands"
                placeholder="Select a brand"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Logo Image" class="mb-3">
            <vue-dropzone
                ref="myVueDropzone_logo"
                id="logo-dropzone"
                :options="dropzoneOptions_logo"
            ></vue-dropzone>
            <button class="btn btn-danger mt-2" @click="removeFile">Remove Logo Image</button>
          </b-form-group>

          <b-form-group label="Header Image" class="mb-3">
            <vue-dropzone
                ref="myVueDropzone_header"
                id="logo-dropzone"
                :options="dropzoneOptions_header"
            ></vue-dropzone>
            <button class="btn btn-danger mt-2" @click="removeFile1">Remove Header Image</button>
          </b-form-group>
          <b-form-group label="Display Order">
            <b-form-input v-model="editableBrand.display_order" />
          </b-form-group>
        </div>
      </div>

    </template>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Cancel</b-button>
      <b-button variant="primary" @click="saveChanges" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>
        Save Changes
      </b-button>
    </template>
  </b-modal>
</template>
<style>
/* Hide the success and remove icons in Dropzone */
.dropzone .dz-success-mark,
.dropzone .dz-error-mark {
  display: none; /* Hide the success (checkmark) and error icons */
}

.dropzone .dz-remove {
  display: none; /* Hide the 'Remove' link */
}

.dropzone .dz-preview .dz-image {
  width: 100%;
  height: 100%;
}

.dropzone .dz-preview .dz-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
