<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Monitor from "@/services/Monitor";
    import Football from "@/services/Football";
    import ModalEditSportCompetition from "@/components/modals/monitor/modalEditSportCompetition";
    import Swal from "sweetalert2";

    export default {
        components: {
            Layout,
            PageHeader,
            ModalEditSportCompetition
        },
        page: {
            title: "Competition",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                error: null,
                tableData: [],
                tableDataTournament: [],
                title: "Competition",
                items: [
                    {
                        text: "Sport Data",
                    },
                    {
                        text: "Competition",
                        active: true,
                    },
                ],
                isBusy: false,
                totalRows: 0,
                totalRowsTournament: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "valid_from",
                sortDesc: true,
                dealData: [],
                teamsData: [],
                sportTeamData: [],
                fields: [

                    {
                        key: "competition_name",
                        label: "Competition name",
                        visible: true,
                    },
                    {
                        key: "competition_code",
                        label: "Competition code",
                        visible: true,
                    },
                    {
                        key: "competition_country",
                        label: "Competition country",
                        visible: true,
                    },
                    {
                        key: "competition_logo",
                        label: "Competition logo",
                        visible: true,
                    },
                    {
                        key: "competition_logo_url",
                        label: "Copy URL",
                        sortable: false,
                    },
                    {
                        key: "provider",
                        label: "Provider",
                        visible: true,
                    },
                    {
                        key: "Action",
                        label: "Action",
                    }
                ],
                statusOptions: [],
                isBusyAll: false,
                totalRowsAll: 0,
                currentPageAll: 1,
                perPageAll: 10,
                pageOptionsAll: [10, 25, 50, 100],
                filterAll: null,
                filterOnAll: [],
                filterData: false,
                loading: false ,
                countryCode : []  ,
                filterInput: {
                    countryCode : '' ,
                },
            };
        },
        async created() {
            await this.getSportCompetitions();
        },
        methods: {
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.totalRowsTournament = filteredItems.length;
                this.currentPage = 1;
            },
            async getSportCompetitions() {
                try {
                    this.filterData = true;
                    this.isBusy = true;
                    const response = await Monitor.getSportCompetitions();
                    this.tableData = response.data.data;
                    this.totalRows = this.tableData.length
                    this.isBusy = false;
                } catch (error) {
                    this.isBusy = false;
                    this.tableData = []
                    this.totalRows = 0
                }
            },
            callModalEditSportCompetition(data) {
                this.$bvModal.show("edit_sport_competition");
                this.sportTeamData = data
            },
            copyUrl(competition_logo){
                navigator.clipboard.writeText(competition_logo)
                    .then(() => {
                        console.log('Team logo copied to clipboard');
                    })
                    .catch(err => {
                        console.error('Error copying team logo to clipboard: ', err);
                    });
            },
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" :fields="fields"/>
        <div class="row">
            <div class="col-12">
                <div class="card" v-show="filterData">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col d-inline-flex align-items-center">
                                <h4 class="card-title m-0">Total Sport Teams: {{ totalRows }}</h4>
                            </div>
                        </div>
                        <div class="row mt-4" v-show="filterData">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">    Show&nbsp;
                                        <b-form-select
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions">

                                        </b-form-select>&nbsp;entries</label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="deals-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Search..."
                                            class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>

                        <!-- Table -->
                        <div class="table-responsive mb-0 font-size-12" v-show="filterData">
                            <b-table
                                :items="tableData"
                                :busy="isBusy"
                                :fields="fields"
                                :visible="fields.visible"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                                show-empty
                                empty-text="No Data Found"
                            >
                                <template v-slot:cell(competition_logo)="data">
                                    <img class="img-fluid" :src="data.item.competition_logo" width="100">
                                </template>
                                <template v-slot:cell(competition_logo_url)="data">
                                    <div v-if="data.item.competition_logo">
                                        <button class="btn btn-link" @click="copyUrl(data.item.competition_logo)" title="Copy URL">
                                            <i class="mdi mdi-content-copy"></i>
                                        </button>
                                    </div>
                                </template>
                              <template v-slot:cell(competition_logo)="data">
                                <div v-if="data.item.competition_logo">
                                  <img :src="data.item.competition_logo" alt="Competition Logo" style="background: #F5F6F8;">
                                </div>
                              </template>

                              <template v-slot:cell(app)="data">
                                    <i v-if="data.item.app == 1" class="mdi mdi-cellphone  fs-6"></i>
                                </template>
                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-primary" title="Edit Sport Team" @click="callModalEditSportCompetition(data.item)">
                                                <i class="uil uil-pen font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <!--Table end -->
                        <div class="row" v-show="filterData">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- MODALS -->
        <ModalEditSportCompetition :sportTeamData="sportTeamData" @onRefresh="getSportCompetitions()"></ModalEditSportCompetition>
        <!-- /MODALS -->
    </Layout>
</template>

