<script>
import { required, url } from "vuelidate/lib/validators";
import validationMessages from "@/components/validations";
import Merchandising from "@/services/Merchandising";
import Swal from "sweetalert2";
import vueDropzone from "vue2-dropzone";

export default {
  components: { vueDropzone, validationMessages },

  data() {
    return {
      position: "",
      link_url: "",
      showModal: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem("csrf_token"),
      error: null,
      positions: [],
      selectFile: '',
      mediaData: '',
      dropzoneOptions_primary: {
        url: "/",
        autoProcessQueue: false,
        addRemoveLinks: true,
        thumbnailWidth: 200,
        thumbnailMethod: "contain",
        maxFilesize: 2,
        acceptedFiles: "image/*",
        maxFiles: 1,
      },
    };
  },

  validations: {
    position: { required },
    link_url: { required, url },
  },

  methods: {
    closeModal() {
      this.resetForm();
      this.showModal = false;
    },
    async getMedia() {
      this.showLoader = true;
      try {
        const response = await Merchandising.getMedia();
        this.mediaData = response.data.data;
      } catch (error) {
        console.error("Error fetching media:", error);
      } finally {
        await this.fetchPosition();
        this.showLoader = false;
      }
    },


    async submitForm() {
      this.tryingToSubmit = true;
      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }

      this.showLoader = true;
      let formData = new FormData();
      formData.append("position", this.position);
      formData.append("link_url", this.link_url);

      const files = this.$refs.myVueDropzone_primary.getAcceptedFiles();
      if (files.length > 0) {
        formData.append("image_url_new", files[0]);
      }

      try {
        await Merchandising.createMedia(formData);
        this.successmsg("Media Created!");
        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.error("Failed to create media:", error);
        this.failedmsg("Failed to Create Media.");
      } finally {
        this.tryingToSubmit = false;
        this.showLoader = false;
      }
    },

    refreshData() {
      this.$emit("onRefresh");
    },

    successmsg(message) {
      Swal.fire("Success", message, "success");
    },

    failedmsg(message) {
      Swal.fire("Error", message, "error");
    },

    resetForm() {
      this.position = "";
      this.positions = "";
      this.link_url = "";
      this.$v.$reset();
      if (this.$refs.myVueDropzone_primary) {
        this.$refs.myVueDropzone_primary.removeAllFiles();
      }
    },
    filterPositions() {
      const mediaPositions = new Set(this.mediaData.map(media => media.position));
      this.positions = this.positions.filter(position => !mediaPositions.has(position.text));
    },

    async fetchPosition() {
      this.showLoader = true;
      try {
        const response = await Merchandising.getMediaPosition();
        if (Array.isArray(response.data.media_positions)) {
          this.positions = response.data.media_positions.map(position => ({
            value: position.position_code,
            text: position.position_name,
          }));
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching positions:", error);
      } finally {
        this.showLoader = false;
        this.filterPositions();

      }
    },

    setData() {
      this.getMedia();
    }

  },
};
</script>


<template>
  <b-modal @shown="setData" @hidden="resetForm" id="add_media" size="l" v-model="showModal" title="Media" title-class="font-18">
    <form @submit.prevent="submitForm">
      <div class="row">

        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
        <div v-if="showLoader" class="card-body d-flex align-items-center justify-content-center">
          <b-spinner large></b-spinner>
        </div>
        <div v-else>
          <b-card-text>
            <template v-if="positions.length > 0">
              <b-form-select
                  v-model="position"
                  :options="positions"
                  :class="{ 'is-invalid': submitted && $v.position.$error }"
                  placeholder="Select a position"
              ></b-form-select>
            </template>
            <template v-else>
              <p class="text-danger">There are no more positions in which you can place an image.</p>
            </template>

            <b-form-group label="Image" class="mb-3" v-if="positions.length > 0">
              <vue-dropzone
                  ref="myVueDropzone_primary"
                  id="primary-dropzone"
                  :options="dropzoneOptions_primary"
                  @vdropzone-files-added="selectFile"
              ></vue-dropzone>
            </b-form-group>


            <b-form-group label="Link URL" class="mb-3" v-if="positions.length > 0">
              <b-form-input v-model.trim="link_url" :class="{ 'is-invalid': submitted && $v.link_url.$error }"></b-form-input>
              <validationMessages v-if="submitted" :fieldName="'Link URL'" :validationName="$v.link_url"></validationMessages>
            </b-form-group>

            <input type="hidden" name="csrf_token" v-model="csrf_token">
          </b-card-text>
        </div>
      </div>
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="submitForm" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
      </b-button>
    </template>
  </b-modal>
</template>

