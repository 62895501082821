<script>
    import { required, minLength, numeric } from "vuelidate/lib/validators";
    import General from '@/services/General';
    import Customers from '@/services/Customers';
    import validationMessages from '@/components/validations'
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";

    export default {
        components:{
            validationMessages , vueDropzone: vue2Dropzone,
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                name: '',
                description: '',
                website: '',
                company_image : '' ,
                logo_url : '' ,
                background_color : '' ,
                photoDeletedCompanyImage : false ,
                photoDeletedLogoUrl : false ,
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                verified_checked: false,

                dropzoneOptions_primary: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles: 'image/*',
                    maxFiles: 3,
                },
            };
        },
        validations: {
            name: {
                required
            },
            description: {
                required
            },
            website: {
                required
            },
            background_color: {
                required
            },
        },
        created() {
        },
        methods: {

            async addCompany(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {

                        await Customers.addCompany({
                            name: this.name,
                            description: this.description,
                            website: this.website,
                            company_image: this.company_image,
                            logo_url: this.logo_url,
                            background_color: this.background_color,
                            active: this.verified_checked == true ? '1' : '',
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            this.successmsg("Changes has been saved");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },
            selectFileCompanyImage(files) {
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_company_image.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one company image');

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_company_image.removeFile(value);
                        });
                    }
                    this.company_image = queuedFiles[0];
                    this.photoDeletedCompanyImage = false;
                }, 250);
            } ,


            selectFileLogoUrl(files) {
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_logo_url.getQueuedFiles();
                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one logo url');

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_logo_url.removeFile(value);
                        });
                    }
                    this.logo_url = queuedFiles[0];
                    this.photoDeletedLogoUrl = false;
                }, 250);
            } ,
           refreshData() {
                this.$emit('onRefresh')
            },

            closeModal() {
                this.showModal = false;
            },
            resetProps(){
                this.name = this.description = this.website =  this.background_color = ''

            }
        }
    };
</script>

<template>
    <b-modal v-model="showModal" @hidden="resetProps" id="add_new_company" title="Add new Customer" title-class="font-18" size="lg" centered>
        <form @submit.prevent="addCompany">
            <div class="row">
                <div class="col">
                    <b-form-group label="Company Name" label-for="formrow-name-input" class="mb-3">
                        <b-form-input
                                v-model.trim="name"
                                type="text"
                                id="name"
                                aria-describedby="name-feedback"
                                :class="{
                                  'is-invalid': submitted && $v.name.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Company Name'" :validationName="$v.name"></validationMessages>
                    </b-form-group>

                    <b-form-group label="Description" label-for="formrow-description-input" class="mb-3">
                        <b-form-input
                                v-model.trim="description"
                                type="text"
                                id="description"
                                aria-describedby="description-feedback"
                                :class="{
                                  'is-invalid': submitted && $v.description.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Last Name'" :validationName="$v.description"></validationMessages>
                    </b-form-group>

                    <b-form-group label="Background color" label-for="formrow-background-color" class="mb-3">
                        <b-form-input
                                v-model.trim="background_color"
                                type="text"
                                id="background_color"
                                aria-describedby="background-color-feedback"
                                :class="{
                                  'is-invalid': submitted && $v.background_color.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Background color'" :validationName="$v.background_color"></validationMessages>
                    </b-form-group>


                    <b-form-group label="Email" label-for="formrow-website-input" >
                        <b-form-input
                                v-model.trim="website"
                                type="text"
                                id="website"
                                aria-describedby="website-feedback"
                                :class="{
                                  'is-invalid': submitted && $v.website.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Email'" :validationName="$v.website"></validationMessages>
                    </b-form-group>
                </div>

                <b-form-checkbox-group>
                    <div class="form-check form-switch form-switch-lg mb-3" >
                        <input v-model="verified_checked"
                               type="checkbox"
                               class="form-check-input"
                               id="companyStatus"
                        />
                        <label class="form-check-label" for="companyStatus">Active</label>
                    </div>
                </b-form-checkbox-group>


                <hr>

                <div class="form-group " >
                    <label for="company_image">Company Image:</label>
                    <template>
                        <vue-dropzone
                                id="company_image"
                                ref="myVueDropzone_company_image"
                                :use-custom-slot="true"
                                :options="dropzoneOptions_primary"
                                @vdropzone-files-added="selectFileCompanyImage"
                                no-progress-bar
                        >
                            <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Drop file here or click to upload.</h4>
                            </div>
                        </vue-dropzone>
                    </template>
                </div>
<hr>
                <div class="form-group " >
                    <label for="logo_url">Logo Url:</label>
                    <template>
                        <vue-dropzone
                                id="logo_url"
                                ref="myVueDropzone_logo_url"
                                :use-custom-slot="true"
                                :options="dropzoneOptions_primary"
                                @vdropzone-files-added="selectFileLogoUrl"
                                no-progress-bar
                        >
                            <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Drop file here or click to upload.</h4>
                            </div>
                        </vue-dropzone>
                    </template>
                </div>


                <div class="col">

                    <hr>
                </div>
            </div>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addCompany" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>