import Http from "./Http";

export default {

async getCategories(){
    return await Http.get(`/merchandise/categories`);
},
async getTags(){
    return await Http.get(`/merchandise/tags`);
},
async getProducts(params = '') {
    return await Http.get(`/merchandise/products?${params}`)
},
async getProduct(product_id) {
    return await Http.get(`/merchandise/products/${product_id}`)
},
async getProductRelated(product_id) {
    return await Http.get(`/merchandise/product/related/${product_id}`)
},
async addRelatedProduct(product_id,rel_slug) {
    return await Http.post(`/merchandise/product/related/${product_id}`, {
        rel_slug: rel_slug
    });
},
async removeRelated(product_id,rel_slug){
    return await Http.delete(`/merchandise/product/related/${product_id}`, {
       data: { rel_slug: rel_slug }
    });
},
async getTagsByProduct(product_id) {
    return await Http.get(`/merchandise/products/${product_id}/tags`)
},
async attachTag(product_id, tag_id) {
    return await Http.post(`/merchandise/product/${product_id}/tag/attach`, {
      tag_id: tag_id,
    });
  },
async detachTag(product_id, tag_id) {
    return await Http.post(`/merchandise/product/${product_id}/tag/detach`, {
      tag_id: tag_id,
    });
  },
async getMedia(params = '') {
    return await Http.get(`/merchandise/media`)
},
async updateMedia(data) {
    return await Http.post(`/merchandise/media`, data, {
        headers: {
            'Content-Type':'multipart/form-data'
        }
    })
},async createMedia(data) {
        return await Http.post(`/merchandise/media`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },

    async deleteMedia(position) {
        return await Http.delete(`/merchandise/media/${position}` , position)
    },

    async getMediaPosition(){
        return await Http.get(`/merchandise/media/position`)
    },

async getPromotions(params = '') {
    return await Http.get(`/merchandise/product/promo?${params}`)
},
async getBestSeller(params = '') {
    return await Http.get(`/merchandise/product/bestseller?${params}`)
},
async removePromotion(id) {
    return await Http.delete(`/merchandise/product/promo?product_id=${id}`)
},
async removeBestSeller(id) {
    return await Http.delete(`/merchandise/product/bestseller?product_id=${id}`)
},
async getProductsAutocomplete(params = '') {
    return await Http.get(`/merchandise/products/autocomplete?${params}`)
},
async addPromotion(id) {
    return await Http.post(`/merchandise/product/promo?product_id=${id}`)
},
async addBestSeller(id) {
    return await Http.post(`/merchandise/product/bestseller?product_id=${id}`)
},
async updateCategorySlug(id,category_slug){
    return await Http.patch(`/merchandise/category/slug`,{
        id:id,
        category_slug:category_slug
    })
},
async updateCategoryOrder(id,order){
    return await Http.post(`/merchandise/category/order/new`,{
        id:id,
        order:order
    })
},
async updateCategoryVisibility(id,visibility){
    return await Http.post("/merchandise/category/visible/new",{
        id:id,
        visibility:visibility
    })
},
    async addBrand(data) {
        return await Http.post(`/merchandise/brands`, data)
    },
    async getMerchandiseBrand(params = '') {
        return await Http.get(`/merchandise/brands?${params}`)
    },
    async removeBrand(id) {
        return await Http.delete(`/merchandise/brands/${id}`)
    },
    async editMerchandiseBrand(data) {
        return await Http.post(`/merchandise/brand`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

};